
import DeleteEdit from "@/modules/services/components/delete-edit.vue";
import { mapActions, mapGetters } from "vuex";
import JobFilterModal from "@/modules/jobs/components/job-filter-modal.vue";
import i18n from "@/vueI18n";

import FilterJobs from "@/modules/jobs/components/filter-jobs.vue";
import FilterJobsTabs from "@/modules/jobs/components/filter-jobs-tabs.vue";
import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;
import moment from "moment";

export default {
  name: "app-jobs-list-table",

  data() {
    return {
      today: new Date(),

      modalFilterVisible: false,
      dialogFilterVisible: false,
      topOfPage: true,
      // currentUser:'ghada',
      searchInputAnimation: false,
      filterTab: "",
      searchOptionFilter: "",
      searchOption: null,
      filterSearch: false,
      deleteJob: false,
      id: "",
      pagination: {
        page: 1,
        rowsPerPage: this.getItemsPerPage(),
      },
      searchModel: null,
      deleteDialogHeader: false,
      search: "",
      filter: "",
      page: 1,
      rowsOld: [],
      searchByOptions: ["city", "position", "workplace"],
      loadingTable: false,
    };
  },
  components: {
    // [DeleteJob.name]: DeleteJob,
    [DeleteEdit.name]: DeleteEdit,
    [FilterJobs.name]: FilterJobs,
    [FilterJobsTabs.name]: FilterJobsTabs,
    [JobFilterModal.name]: JobFilterModal,
    // [FilterServices.name]: FilterServices
  },

  methods: {
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      doFetch: "jobs/list/doFetch",
      doFetchFromRoute: "jobs/list/doFetchFromRoute",
      setRows: "jobs/list/setRows",
      getPreviousRecordsBatch: "jobs/list/getPreviousRecordsBatch",
      setMountedTable: "jobs/list/setMountedTable",
      resetRecord: "jobs/view/resetRecord",
      doFetchCity: "jobs/list/doFetchCity",
      doFetchWorkplace: "jobs/list/doFetchWorkplace",
      doFetchJobPosition: "jobs/list/doFetchJobPosition",
      setMountedCityTable: "jobs/list/setMountedCityTable",
      setMountedPositionTable: "jobs/list/setMountedPositionTable",
      setMountedWorkplaceTable: "jobs/list/setMountedWorkplaceTable",

      setLoadFirstRecord: "jobs/list/setLoadFirstRecord",
      setLoadMoreLast: "jobs/list/setLoadMoreLast",
      setDisableNext: "jobs/list/setDisableNext",
      setDisablePrevious: "jobs/list/setDisablePrevious",
      resetCursor: "jobs/list/resetCursor",
      setCreatorToRow: "jobs/list/setCreatorToRow",

      setJobDetailsStarted: "jobs/list/setJobDetailsStarted",
      setJobDetailsSuccess: "jobs/list/setJobDetailsSuccess",
    }),
    addNewItem() {
      this.$emit("addNewItem");
    },
    openFilterModal() {
      this.modalFilterVisible = true;
      this.dialogFilterVisible = true;
    },
    closeFilterModal(value) {
      if (value) {
        this.closeFilter(value);
      }
      this.dialogFilterVisible = false;
      setTimeout(() => {
        this.modalFilterVisible = false;
      }, 500);
    },
    goToViewJob(id) {
      this.resetRecord();
      this.$router.push(`/job-opportunities/${id}`);
    },
    datePresenter(date) {
      // let datePresentation = new Date(date.seconds * 1000);
      // datePresentation = datePresentation.toString().slice(0, 15);
      // return datePresentation;
      const language = localStorage.getItem("language");
      return moment(new Date(date.seconds * 1000))
        .locale(language)
        .format("DD-MM-yyyy hh:mm a");
    },
    descriptionPresenter(value) {
      // if (description) {
      //   if (this.isScreenXs) {
      //     return description.slice(0, 60);
      //   } else {
      //     return description.slice(0, 400);
      //   }
      // }
      var description = "";

      // if(this.is_screen_xs || this.is_screen_md || this.is_screen_sm){
      //   description = value.toString().slice(0,40)
      //   description = value.length > 40 ? description + '...' : description
      // }
      if (!this.is_screen_xs) {
        description = value.toString().slice(0, 45);
        description = value.length > 45 ? description + "..." : description;
      } else {
        description = value.toString().slice(0, 30);
        description = value.length > 30 ? description + "..." : description;
      }
      return description;
    },
    searchInJobOpportunities() {
      if (this.currentRoute != "my-jobs") {
        const ele = document.getElementById("header");
        const target = getScrollTarget(ele);
        const offset = ele.offsetTop;
        const duration = 0;
        setScrollPosition(target, offset, duration);
        this.searchInputAnimation = true;
        this.showFilter = true;
        this.$emit("changeJobHeader", true);
      }
    },
    scrollHandler(scroll) {
      if (this.currentRoute == "job-opportunities") {
        if (scroll.position == 0 && !this.deleteDialogHeader) {
          this.setHeaderBg("bg-transparent");
          // this.topOfPage = true
        }
        if (scroll.position > 0 && !this.deleteDialogHeader) {
          // this.topOfPage = false
          this.setHeaderBg("bg-shadow");
          this.searchInputAnimation = false;
          this.$emit("changeJobHeader", false);
        }
        // if(scroll.position > 0 && this.deleteDialogHeader){
        //     console.log('this.deleteDialog 3',this.deleteDialogHeader);
        //     this.setHeaderBg('bg-shadow')
        // }
        if (scroll.position == 0 && this.deleteDialogHeader) {
          this.setHeaderBg("bg-shadow");
        }
      }

      // if(scroll.position == 0){
      //     this.setHeaderBg('bg-transparent')
      //     // this.searchInputAnimation = true
      // }
      // if(scroll.position > 0)  {
      //     this.setHeaderBg('bg-shadow')
      //     this.searchInputAnimation = false
      //     this.$emit('changeJobHeader',false)
      // }
    },
    checkOption(value) {
      if (this.searchModel === "city") {
        if (value.cityId.includes(this.searchOption)) {
          return value;
        }
      }
      if (this.searchModel === "position") {
        if (value.positionId.includes(this.searchOption)) {
          return value;
        }
      }
      if (this.searchModel === "workplace") {
        // if(value.workplaceId){
        if (value.workplaceId.includes(this.searchOption)) {
          return value;
        }
        // }
      }
    },

    async closeFilter(value) {
      // debugger
      if (value) {
        this.filterSearch = !this.filterSearch;
        this.resetCursor();
        this.page = 1;

        if (value.model) {
          this.searchModel = value.model;
          this.searchOption = value.id;
          if (this.searchModel === "type") {
            this.search = "type";
            this.filter = this.searchOption;
            await this.doFetch({
              currentRoute: this.currentRoute,
              currentUser: this.currentUser,
              limit: this.getItemsPerPage(),
              searchBy: "type",
              filter: this.searchOption,
            });
            this.$router.push({
              query: {
                page: 1,
                searchBy: this.search,
                filter: this.filter,
              },
            });
          }
          if (this.searchModel === "city") {
            this.search = "city";
            this.filter = this.searchOption;
            let city = this.cities.filter(
              (city) => city.id == this.searchOption
            );
            this.searchOptionFilter = city[0];
            await this.doFetch({
              currentRoute: this.currentRoute,
              currentUser: this.currentUser,
              limit: this.getItemsPerPage(),
              searchBy: "city",
              filter: this.searchOption,
            });
            this.$router.push({
              query: {
                page: 1,
                searchBy: this.search,
                filter: this.filter,
              },
            });
          }
          if (this.searchModel === "position") {
            this.search = "position";
            this.filter = this.searchOption;
            let jobPositionName = this.positions.filter(
              (position) => position.id == this.searchOption
            );
            this.searchOptionFilter = jobPositionName[0];
            await this.doFetch({
              limit: this.getItemsPerPage(),
              searchBy: "position",
              filter: this.searchOption,
            });
            this.$router.push({
              query: {
                page: 1,
                searchBy: this.search,
                filter: this.filter,
              },
            });
          }
          if (this.searchModel === "workplace") {
            this.search = "workplace";
            this.filter = this.searchOption;
            let workplaceName = this.workplaces.filter(
              (workplace) => workplace.id == this.searchOption
            );
            this.searchOptionFilter = workplaceName[0];
            await this.doFetch({
              limit: this.getItemsPerPage(),
              searchBy: "workplace",
              filter: this.searchOption,
            });
            this.$router.push({
              query: {
                page: 1,
                searchBy: this.search,
                filter: this.filter,
              },
            });
          }
        } else {
          this.search = null;
          this.filter = null;
          this.$router.push({
            query: {
              page: this.page,
            },
          });
          this.doFetch({
            limit: this.getItemsPerPage(),
            currentRoute: this.currentRoute,
            currentUser: this.currentUser,
          });
        }
        this.rowsOld = this.rows;
      }
      // else {
      //   console.log("do nothing");
      // }
    },

    openFilter() {
      this.filterSearch = !this.filterSearch;
    },
    getItemsPerPage() {
      const { screen } = this.$q;
      if (screen.lt.sm) {
        return 4;
      }
      if (screen.lt.md) {
        return 6;
      }
      if (screen.lt.lg) {
        return 9;
      }
      if (screen.lt.xl) {
        return 9;
      }
      return 9;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    openDeleteDialog(id) {
      this.id = id;
      this.deleteJob = true;
      this.deleteDialogHeader = true;
    },
    async closeDeleteDialog() {
      this.deleteDialogHeader = false;
    },
    async doRefreshRows() {
      this.closeDeleteDialog();
      this.setRows([]);
      this.resetCursor();
      this.page = 1;
      this.$router.push({
        query: {
          page: this.page,
        },
      });
      await this.doFetch({
        page: 1,
        limit: this.getItemsPerPage(),
        currentUser: this.currentUser,
        currentRoute: this.currentRoute,
        searchBy: this.search,
        filter: this.filter,
      });
    },
    close() {
      this.deleteJob = false;
      this.deleteDialogHeader = false;
    },
    removeFilter() {
      this.searchOption = "";
      // this.jobsWithCreators = this.jobsWithoutFilter
      // this.jobs = this.jobsWithoutFilter
      this.setJobs(this.jobsWithoutFilter);
    },

    goToEditJob() {
      this.$router.push({
        path: "add-job",
        query: {
          edit: true,
        },
      });
    },
    disableNext() {
      if (
        this.endAt == this.totalAcceptedJobs ||
        this.endAt > this.totalAcceptedJobs
      ) {
        return true;
      } else {
        return false;
      }
    },
    disablePrevious() {
      return this.startAt == 0 ? true : false;
    },
    async goToPreviousPage() {
      this.page = parseInt(this.page) - 1;
      if (this.search) {
        await this.getPreviousRecordsBatch({
          limit: this.getItemsPerPage(),
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
          searchBy: this.search,
          filter: this.filter,
        });
        this.$router.push({
          query: {
            page: this.page,
            searchBy: this.search,
            filter: this.filter,
          },
        });
      } else {
        await this.getPreviousRecordsBatch({
          limit: this.getItemsPerPage(),
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
        });
        this.$router.push({
          query: {
            page: this.page,
          },
        });
      }
      this.rowsOld = this.rows;
    },

    async loadMore() {
      this.page = parseInt(this.page) + 1;
      if (this.search) {
        this.$router.push({
          query: {
            page: this.page,
            searchBy: this.search,
            filter: this.filter,
          },
        });
        await this.doFetch({
          limit: this.getItemsPerPage(),
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
          searchBy: this.search,
          filter: this.filter,
        });
      } else {
        await this.doFetch({
          limit: this.getItemsPerPage(),
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
        });
        this.$router.push({
          query: {
            page: this.page,
          },
        });
      }
      this.rowsOld = this.rows;
    },
    async navigateBackwardFroward() {
      if (
        this.$route.name == "job-opportunities" ||
        this.$route.name == "my-jobs"
      ) {
        // debugger
        this.search = this.$route.query.searchBy;
        this.filter = this.$route.query.filter;
        this.page = parseInt(this.$route.query.page);

        this.setRows([]);
        this.resetCursor();
        if (
          this.$route.query.searchBy &&
          this.searchByOptions.includes(this.$route.query.searchBy) &&
          this.$route.query.filter
        ) {
          if (
            this.$route.query.page &&
            parseInt(this.$route.query.page) > 0 &&
            this.isNumeric(this.$route.query.page)
          ) {
            await this.doFetchFromRoute({
              currentUser: this.currentUser,
              currentRoute: this.currentRoute,
              page: parseInt(this.$route.query.page),
              limit: this.getItemsPerPage(),
              searchBy: this.$route.query.searchBy,
              filter: this.$route.query.filter,
            });
          } else {
            this.page = 1;
            await this.doFetchFromRoute({
              currentUser: this.currentUser,
              currentRoute: this.currentRoute,
              page: 1,
              limit: this.getItemsPerPage(),
              searchBy: this.$route.query.searchBy,
              filter: this.$route.query.filter,
            });
          }
        } else {
          if (
            this.$route.query.page &&
            parseInt(this.$route.query.page) > 0 &&
            this.isNumeric(this.$route.query.page)
          ) {
            await this.doFetchFromRoute({
              page: parseInt(this.$route.query.page),
              limit: this.getItemsPerPage(),
              currentUser: this.currentUser,
              currentRoute: this.currentRoute,
            });
          } else {
            // debugger
            this.page = 1;
            await this.doFetch({
              page: 1,
              limit: this.getItemsPerPage(),
              currentUser: this.currentUser,
              currentRoute: this.currentRoute,
            });
          }
        }
        this.rowsOld = this.rows;
      } else {
        // console.log("do not call");
        // debugger
      }
    },
  },
  computed: {
    ...mapGetters({
      currentRoute: "jobs/list/currentRoute",
      currentUser: "auth/currentUser",
      jobs: "jobs/list/jobs",
      jobsWithoutFilter: "jobs/list/jobsWithoutFilter",
      currentUserJobs: "jobs/list/currentUserJobs",
      loading: "jobs/list/loading",
      mountedTable: "jobs/list/mountedTable",
      is_screen_xs: "layout/is_screen_xs",
      is_screen_sm: "layout/is_screen_sm",
      is_screen_md: "layout/is_screen_md",
      mountedCityTable: "jobs/list/mountedCityTable",
      mountedPositionTable: "jobs/list/mountedPositionTable",
      mountedWorkplaceTable: "jobs/list/mountedWorkplaceTable",
      loadingAddMoreJobs: "jobs/list/loadingAddMoreJobs",

      cities: "jobs/list/cities",
      workplaces: "jobs/list/workplaces",
      positions: "jobs/list/jobPositions",

      //#region [vuexfire]
      rows: "jobs/list/rows",
      addedCreatorId: "jobs/list/addedCreatorId",
      rowsFromRoute: "jobs/list/rowsFromRoute",
      //#endregion

      recordAfterLast: "jobs/list/recordAfterLast",
      recordBeforeFirst: "jobs/list/recordBeforeFirst",

      detailsLoading: "jobs/list/detailsLoading",
    }),

    rowsPerPageOptions() {
      return [2];
    },
    isScreenXs() {
      // console.log('screennnnn',this.$q.screen.lt)
      if (
        this.$q.screen.lt.lg &&
        this.$q.screen.lt.md &&
        this.$q.screen.lt.sm &&
        this.$q.screen.lt.xl
      ) {
        return true;
      }
      return false;
    },
    isRTL() {
      return i18n.locale == "ar";
    },
    rowsToBeDisplayed() {
      if (this.rows.length > 0 || !this.loading) {
        return this.rows;
      } else {
        return this.rowsOld;
      }
    },
  },
  watch: {
    rows(newval) {
      console.log("rows==>", newval.length);
      if (newval.length > 0) {
        this.setLoadFirstRecord();
        this.setLoadMoreLast();

        this.setDisablePrevious({
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
          searchBy: this.search,
          filter: this.filter,
        });
        this.setDisableNext({
          currentRoute: this.currentRoute,
          currentUser: this.currentUser,
          searchBy: this.search,
          filter: this.filter,
        });
      }
    },
    // recordBeforeFirst(newval) {
    //   console.log("newval recordBeforeFirst", newval);
    // },
    addedCreatorId(newval) {
      if (newval) {
        // this.setCreatorToRow();
      }
    },
    rowsFromRoute(change) {
      console.log("==>rowsFromRoute", change);
      const page = this.$route.query.page;
      const limit = this.getItemsPerPage();
      const rowsFromRouteToBeDisplayed = change.slice(
        page * limit - limit,
        page * limit + 1
      );
      this.setRows(rowsFromRouteToBeDisplayed);
    },
  },
  mounted() {
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardFroward();
    };
  },
  async created() {
    this.setJobDetailsStarted();
    if (!this.mountedWorkplaceTable) {
      await this.doFetchWorkplace();
      this.setMountedWorkplaceTable(true);
    }
    if (!this.mountedPositionTable) {
      await this.doFetchJobPosition();
      this.setMountedPositionTable(true);
    }
    if (!this.mountedCityTable) {
      await this.doFetchCity();
      this.setMountedCityTable(true);
    }
    this.setJobDetailsSuccess();

    this.loadingTable = true;
    // debugger
    this.page =
      this.$route.query.page && parseInt(this.$route.query.page) > 0
        ? parseInt(this.$route.query.page)
        : 1;
    this.search = this.$route.query.searchBy
      ? this.$route.query.searchBy
      : null;
    this.filter = this.$route.query.filter ? this.$route.query.filter : null;
    this.deleteDialogHeader = false;

    // this.setJobDetailsStarted()
    // if (!this.mountedWorkplaceTable) {
    //   await this.doFetchWorkplace();
    //   this.setMountedWorkplaceTable(true);
    // }
    // if (!this.mountedPositionTable) {
    //   await this.doFetchJobPosition();
    //   this.setMountedPositionTable(true);
    // }
    // if (!this.mountedCityTable) {
    //   await this.doFetchCity();
    //   this.setMountedCityTable(true);
    // }
    // this.setJobDetailsSuccess()

    this.setRows([]);
    this.resetCursor();
    if (
      this.$route.query.searchBy &&
      this.searchByOptions.includes(this.$route.query.searchBy) &&
      this.$route.query.filter
    ) {
      if (
        this.$route.query.page &&
        parseInt(this.$route.query.page) > 0 &&
        this.isNumeric(this.$route.query.page)
      ) {
        await this.doFetchFromRoute({
          currentUser: this.currentUser,
          currentRoute: this.currentRoute,
          page: parseInt(this.$route.query.page),
          limit: this.getItemsPerPage(),
          searchBy: this.$route.query.searchBy,
          filter: this.$route.query.filter,
        });
      } else {
        //   debugger
        this.page = 1;
        this.$router.push({
          query: {
            page: 1,
            searchBy: this.$route.query.searchBy,
            filter: this.$route.query.filter,
          },
        });
        await this.doFetchFromRoute({
          currentUser: this.currentUser,
          currentRoute: this.currentRoute,
          page: 1,
          limit: this.getItemsPerPage(),
          searchBy: this.$route.query.searchBy,
          filter: this.$route.query.filter,
        });
      }
    } else {
      if (
        this.$route.query.page &&
        this.$route.query.page > 0 &&
        this.isNumeric(this.$route.query.page)
      ) {
        await this.doFetchFromRoute({
          page: parseInt(this.$route.query.page),
          limit: this.getItemsPerPage(),
          currentUser: this.currentUser,
          currentRoute: this.currentRoute,
        });
      } else {
        this.page = 1;
        this.$router.push({
          query: {
            page: 1,
          },
        });
        await this.doFetch({
          page: 1,
          limit: this.getItemsPerPage(),
          currentUser: this.currentUser,
          currentRoute: this.currentRoute,
        });
      }
    }
    this.rowsOld = this.rows;
    this.setMountedTable(true);
    this.loadingTable = false;

    // this.setJobDetailsStarted()
    // if (!this.mountedWorkplaceTable) {
    //   await this.doFetchWorkplace();
    //   this.setMountedWorkplaceTable(true);
    // }
    // if (!this.mountedPositionTable) {
    //   await this.doFetchJobPosition();
    //   this.setMountedPositionTable(true);
    // }
    // if (!this.mountedCityTable) {
    //   await this.doFetchCity();
    //   this.setMountedCityTable(true);
    // }
    // this.setJobDetailsSuccess()
  },
};
