
export default {
    name:'app-job-form-error-modal',
    props:['visible','canNotAdd'],
    computed:{
      dialogVisible:{
            get(){
                // setTimeout(() => {
                    return this.visible
                // },500)
                
            },
            set(){
                 this.$emit('close')
            }
        },
    }

}
