
import i18n from '@/vueI18n';
import { mapGetters, mapActions } from 'vuex';
export default {
    name:'app-job-filter-modal',
    props:['visible'],
    data(){
        return{
            //  filter: 'tradeName',
            optionName: null,
            disabled: true,
            filterModel: null,
            filterId: null
        }
    },
    computed:{
         dialogVisible:{
            get(){
                return this.visible               
            },
            set(){
                 this.$emit('close')
            }
        },
        isRTL(){
            return i18n.locale == "ar"
        },
        ...mapGetters({
            cities: 'jobs/list/cities', 
            workplaces:'jobs/list/workplaces',
            jobPositions:'jobs/list/jobPositions',
        })
        
    },
    // watch:{
    //     cities(newvalue){
    //         console.log('cities watcher',newvalue);
    //     }
    // },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        },
        showOptionName(){         
            // debugger
            if(this.filterModel == 'city'){
              this.optionName = this.cities.find((ele) => ele.id == this.filterId)
            }
            if(this.filterModel == 'workplace'){
              this.optionName = this.workplaces.find((ele) => ele.id == this.filterId)
            }
            if(this.filterModel == 'position'){
              this.optionName = this.jobPositions.find((ele) => ele.id == this.filterId)
            }
            if(this.isRTL){
                return this.optionName.name.ar
            }
            return this.optionName.name.en
        },
        filterOption(model,option){
          // this.filterId = option
        if(model === 'city'){
            this.$emit('close',{model: 'city',id: option})
        }
        if(model === 'position'){
          // debugger
            this.$emit('close',{ model: 'position', id: option})
        }
        if(model === 'workplace'){
            this.$emit('close',{ model: 'workplace', id: option})
        }
        },
        ...mapActions({
            doFetchCity: 'jobs/list/doFetchCity'
        })
    },
    mounted(){
      // this.filterModel = this.$route.query.searchBy ? this.$route.query.searchBy : null
      // this.filterId = this.$route.query.filter ? this.$route.query.filter : null
      this.filterId = this.$route.query.filter ? this.$route.query.filter : null
      if(this.$route.query.searchBy == 'city'){
        this.filterModel = 'city'
      }
      if(this.$route.query.searchBy == 'workplace'){
        this.filterModel = 'workplace'
      }
      if(this.$route.query.searchBy == 'position'){
        this.filterModel = 'position'
      }
    }
}
