
// import firebase from 'firebase/app'
import { mapGetters } from "vuex";
import "firebase/firestore";
import i18n from "@/vueI18n";
export default {
  name: "app-filter-jobs",
  props: ["searchInputAnimation"],
  data() {
    return {
      filterTab: "",
      optionName: null,
      filterModel: null,
      filterId: null
      // cities:[],
      // workplaces:[],
      // positions:[],
    };
  },
  computed: {
    // isRTL() {
    //     return localStorage.getItem("language") == "ar";
    // },
    isRTL() {
      return i18n.locale == "ar";
    },
    ...mapGetters({
      cities: "jobs/list/cities",
      workplaces: "jobs/list/workplaces",
      positions: "jobs/list/jobPositions",
    }),
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    // showOptionName() {
    //   if (this.isRTL) {
    //     console.log("optionName ar", this.optionName.ar);
    //     return this.optionName.ar;
    //   }
    //   console.log("optionName en", this.optionName.en);
    //   return this.optionName.en;
    // },
    showOptionName(){         
      if(this.filterModel == 'city'){
        this.optionName = this.cities.find((ele) => ele.id == this.filterId)
      }
      if(this.filterModel == "workplace"){
        this.optionName = this.workplaces.find((ele) => ele.id == this.filterId)
      }
      if(this.filterModel == 'position'){
        this.optionName = this.positions.find((ele) => ele.id == this.filterId)
      }
      if(this.isRTL){
          return this.optionName.name.ar
      }
      return this.optionName.name.en
    },
    filterOption(model, optionId, optionName) {
      this.filterModel = model;
      this.optionName = optionName;
      this.filterId = optionId
      if (model === "city") {
        // this.expandedCity = false
        // this.expandedPosition = false
        // this.expandedWorplace = false
        this.$emit("close", { model: "city", id: optionId });
      }
      if (model === "position") {
        // this.expandedCity = false
        // this.expandedPosition = false
        // this.expandedWorplace = false
        this.$emit("close", { model: "position", id: optionId });
      }
      if (model === "workplace") {
        // this.expandedCity = false
        // this.expandedPosition = false
        // this.expandedWorplace = false
        this.$emit("close", { model: "workplace", id: optionId });
      }
      if(!model){
            // this.expandedCity = false
            // this.expandedPosition = false
            // this.expandedWorplace = false
            this.$emit('close',{ model: null, id: null})
        }
    },
    removeFilter() {
      this.filterModel = null;
    },
  },
  async mounted() {
    // console.log("filterModel", this.filterModel);
    this.filterId = this.$route.query.filter ? this.$route.query.filter : null
    if(this.$route.query.searchBy == 'city'){
      this.filterModel = 'city'
      this.filterTab = 'city'
    }
    if(this.$route.query.searchBy == 'workplace'){
      this.filterModel = 'workplace'
      this.filterTab = 'workplace'
    }
    if(this.$route.query.searchBy == 'position'){
      this.filterModel = 'position'
      this.filterTab = 'position'
    }
  },
};
