
import i18n from "@/vueI18n";
export default {
  name: "app-filter-tabs",
  data() {
    return {
      filterTab: "",
      filterModel: null,
    };
  },
  computed: {
    isRTL() {
      return i18n.locale == "ar";
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    filterOption(model) {
      if (model === "individual") {
        this.$emit("close", { model: "type", id: "individual" });
      }
      if (model === "company") {
        this.$emit("close", { model: "type", id: "company" });
      }
      if (model === "all") {
        this.$emit("close", { model: null, id: null });
      }
      if (!model) {
        this.$emit("close", { model: null, id: null });
      }
    },
  },
  async mounted() {
    this.filterTab = "all";
  },
};
